<template>
  <suy-table :reload="loadData" v-model:columns="columns">
    <template #search>
      <a-form :model="formState" layout="inline">
        <a-form-item label="">
          <a-input v-model:value="formState.sponsor" placeholder="发起人" />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="loadData">
            搜索
          </a-button>
        </a-form-item>
      </a-form>
    </template>
    <a-table :dataSource="dataSource" :columns="columns" :rowKey="(record, index) => { return index }" :pagination="pagination"
      :loading="loading">
      <template #action="{ record }">
        <a v-if="record.status.value === 0" @click="opsCheck(record.id)">处理</a>
      </template>
    </a-table>
  </suy-table>
  <a-drawer v-model:visible="visible" width="720" title="审核" maskClosable="true">
    <a-form :model="form" layout="vertical">
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item label="订单号">
            {{ form.orderId }}
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item label="老的车牌/车架号">
           <div v-for="item in  form.orderVehicles" :key="item.id" class="m-r1">
            <span v-if="item.orderVehicleId === form.orderVehicleId">
              {{ item.vinNo }}
            </span>
           </div>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="18">
          <a-form-item label="新的车牌/车架号">
            {{ form.vinNoNew }}
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="24">
          <a-form-item label="原因">
            {{ form.reason }}
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="24" style="backgroundColor:#11acb1;font-weight: bold">
          <a-form-item label="系统信息">
            推送时间：{{ form.pushTime }}
            <template v-for="(item, index) in  form.orderVehicles" :key="index">
              <tr>
                第{{ index + 1 }}辆
              </tr>
              <tr>
                <td class="label-td">车型</td>
                <td>{{ item.brand }} · {{ item.model }}</td>
              </tr>
              <tr>
                <td class="label-td">车牌/车架号</td>
                <td>{{ item.vinNo }}</td>
              </tr>
              <td class="label-td">状况</td>
              <td>{{ item.status.label }}</td>
              <tr>
                <td class="label-td">保险公司</td>
                <td>{{ item.insuranceCompany.label }}</td>
              </tr>
              <tr>
                <td class="label-td">保额（万元）</td>
                <td>{{ item.insuranceFee }}</td>
              </tr>
            </template>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="16">
        <a-col :span="24">
          <a-form-item label="审核原因">
            <a-textarea v-model:value="form.remark" :rows="6" :maxlength="1000" showCount />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-popconfirm title="确定同意? " @confirm="audit(1)">
            <a-button type="primary">同意</a-button>
          </a-popconfirm>
          <a-popconfirm title="确定拒绝? " @confirm="audit(2)">
            <a-button>拒绝</a-button>
          </a-popconfirm>
        </a-col>
      </a-row>
    </a-form>
  </a-drawer>
</template>
<script>
import SuyTable from '@/components/SuyTable'
import { reactive, toRefs } from 'vue'
import { opsPage, query, editAudit } from '@/api/trans/ops/edit'
import { detail } from '@/api/trans/order'
import { onMounted } from 'vue'
import { message } from "ant-design-vue";
import { amountUppercase } from "@/utils/util";
export default {
  components: {
    SuyTable
  },
  setup () {
    const state = reactive({
      columns: [
        {
          title: '发起人',
          dataIndex: 'sponsor',
          key: 'sponsor'
        },
        {
          title: '发起时间',
          dataIndex: 'createTime',
          key: 'createTime'
        },
        {
          title: '分类',
          dataIndex: 'subType.label',
          key: 'subType.label'
        },
        {
          title: '修改原因',
          dataIndex: 'reason',
          key: 'reason'
        },
        {
          title: '状态',
          dataIndex: 'status.label',
          key: 'status.label'
        },
        {
          title: '操作人',
          dataIndex: 'operation',
          key: 'operation'
        },
        {
          title: '处理时间',
          dataIndex: 'operationTime',
          key: 'operationTime'
        },
        {
          title: '处理说明',
          dataIndex: 'remark',
          key: 'remark'
        },
        {
          title: '操作',
          key: 'action',
          slots: {
            customRender: 'action'
          }
        }
      ],
      dataSource: [],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      loading: false,
      visible: false,
      check: true,
      formState: {
        type: [],
        child: [],
        remark: ''
      },
      form: {}
    })
    const layout = {
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      }
    }
    const opsCheck = (id) => {
      state.form = {}
      state.form.orderId = '获取中'
      state.form.vinNo = '获取中'
      state.form.vinNoNew = '获取中'
      state.visible = true
      query(id).then(res => {
        // console.log(res)  // 主要动态数据
        if (res.code === 10000) {
          state.form = res.data
          state.form.id = id

          detail(state.form.orderId, {}).then(res => {
            if (res.code === 10000) {
              state.form.pushTime = res.data.order.pushTime
              state.form.orderVehicles = res.data.orderVehicles
            }
          })
        }
      }).finally(() => {
      })
    }
    const handleOk = () => {
      if (!state.check) {
        state.visible = false
      }
    }
    const audit = (type) => {
      if (type === 2) {
        if (state.form.remark === '' || state.form.remark === null || state.form.remark === undefined) {
          message.info('拒绝请填写原因')
          return
        }
      }
      editAudit({
        audit: type,
        opsId: state.form.id,
        remark: state.form.remark
      }).then(res => {
        // console.log(res)  // 主要动态数据
        if (res.code === 10000) {
          message.info('审核成功')
          state.visible = false
        }
      }).finally(() => {
        loadData()
      })
    }
    const loadData = () => {
      state.loading = true
      opsPage({
        ...state.formState,
        audit: 1,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        // console.log(res)  // 主要动态数据
        if (res.code === 10000) {
          state.dataSource = res.data.records
          state.pagination.current = res.data.current
          state.pagination.pageSize = res.data.size
          state.pagination.total = res.data.total
        }
      }).finally(() => {
        state.loading = false
      })
    }
    onMounted(() => {
      loadData()
    })
    return {
      ...toRefs(state),
      layout,
      loadData,
      handleOk,
      opsCheck,
      audit
    }
  }
}
</script>

<style></style>
